import React from 'react'
import './about.css'
import LogoShop from "../assets/shop.PNG"
import LogoGrem from '../assets/grem.PNG'
import Logomarg from '../assets/marg.PNG'


const About = () => {
  return (
    <div id='about' className='about__container'>
      <hr />
      <div className="about__titul">
         <h2>Мои проекты </h2>
      </div>
     <div className="all__about">
        <div className="about__project">
        
          <div className="about__img">
            <img src={LogoShop} alt="" />
          </div>
            <div className="about__name">
              <p>shop</p>
            </div>
          <button>Посмотреть</button> 
        </div>

        <div className="about__project">
        
        <div className="about__img">
          <img src={LogoGrem} alt="" />
        </div>
          <div className="about__name">
            <p>gtem </p>
          </div>
          <button>Посмотреть</button> 
        </div>

        <div className="about__project">
        
        <div className="about__img">
          <img src={Logomarg} alt="" />
        </div>
          <div className="about__name">
            <p>Сияние</p>
          </div>
          <button>Посмотреть</button> 
        </div>
     </div>
      </div>
  )
}

export default About