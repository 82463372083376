import React from 'react'
import './navbar.css'
import { DiGithubBadge } from "react-icons/di"; //<DiGithubBadge />
import { FaTelegramPlane } from "react-icons/fa"; //<FaTelegramPlane />
import { IoMailSharp } from "react-icons/io5"; //<IoMailSharp />
import { DiReact } from "react-icons/di"; //<DiReact />
import { ImHome } from "react-icons/im"; //<ImHome />

const Navbar = () => {
  return (
    <div className='container__navbar'>
      <div className="navbar__home">
        <a href="#header">
         <ImHome className='icon' />
          <h3>Home</h3>
        </a>
      </div>
      <div className="navbar__prodgect">
        <a href="#about">
          <DiReact className='icon__1'/>
          <h3>About</h3>
          </a>
      </div>
      <div className="navbar__connect">
       <div className="connect__icon">
      <a href="https://github.com/Artiishock"  target="_blank"><DiGithubBadge  /> </a>
      <a href="https://t.me/artiishocker"  target="_blank"><FaTelegramPlane  /></a>
      <a href=""  target="_blank"><IoMailSharp /></a>
        </div>
         <hr /> 
        
      </div>

    </div>
  )
}

export default Navbar