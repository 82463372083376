import React from 'react'
import'./header.css'
import Avatar from "../assets/photo_2024-10-09_23-29-05.jpg"

 

const Header = () => {
  return (
 
    <div id='header'  className='header__container'>
      <div className="resume">
        <div className="my__info">
         <div>
          <h1>Голубев Артем2.02</h1>
          <h3>21 год</h3>
         
          <h3>Телефон:+7 (999) 233 23 00</h3>
           <hr />
          <h3>Цель</h3>
          <p>
              Ищу возможность работать в команде разработчиков и развиваться в сфере 
              фронтенд-разработки. Готов активно учиться и применять новые технологии,
              особенно в области React, для создания современных и эффективных веб-приложений.
            </p>
            <hr />
            <h3>Ключевые навыки</h3>
            <p>
              Верстка сайтов: Уверенно работаю с HTML5, CSS3 (включая Flexbox, Grid), JavaScript. Знаю основы адаптивной и кроссбраузерной верстки, использую методологию БЭМ.
              </p>
              <p>
                Учусь React: Активно изучаю React и уже имею опыт создания простых компонентов, работы с состоянием, JSX. Постоянно совершенствую свои знания и расширяю технический стек.
             </p>
              <p>
                Инструменты разработки: Git, Figma (для работы с макетами), VS Code, браузерные инструменты разработчика.
              </p>
          </div>
          <img src={Avatar} alt="" />
        </div> 
        
        <div className="target">
          
        </div>
      </div>
    </div>

  )
}

export default Header


