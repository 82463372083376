import './App.css';
import Header from "./componions/header/Header.jsx"
import Navbar from "./componions/navbar/Navbar.jsx"
import About from  "./componions/about/About.jsx"
import Footer from "./componions/footer/Footer.jsx"

function App() {
  return (
    <div className="App">
        <Navbar/> 
        <Main/>
        
        
        
    </div>
  );
}

function Main() {
  return(
    <div>
      <Header/>
      <About/>
      <Footer/> 
    </div>
  );
}

export default App;
